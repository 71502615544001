import React from 'react';
import PropTypes from 'prop-types';

import './layout.css';
import Header from './Header';
import Footer from './Footer';
import { globalHistory } from '@reach/router';


const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header/>
      <div
        style={{
          height: '100%',
        }}
      >
        <main>{children}</main>
        {globalHistory.location.pathname !== '/' && <Footer/>}
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
