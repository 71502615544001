import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { TweenLite } from 'gsap/TweenMax';
import { GlobalDispatchContext, GlobalStateContext } from '../context/GlobalContextProvider';
import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';
import Logo from '../svg/logo.inline.svg';

const Nav = styled.nav`
  overflow: hidden;
  padding: 0;
`;

const NavbarUl = styled.ul`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 -1em;
  text-align: center;
  
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const NavbarLi = styled.li`
  margin: 0 1em;
  color: #dee7e7;
  font-family: 'Montserrat', sans-serif;
  font-size: 12rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const CeramarkLogoLabel = styled.h1`
  position: relative;
  margin-left: 10px;
  color: #dee7e7;
  font-family: 'Montserrat', sans-serif;
  font-size: 22rem;
  font-weight: 400;
`;

const LogoWrapper = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
`;

const NavigationItem = styled(Link)`
  display: block;
  transition: color .3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  padding: 28px 0;
  margin: auto;
  color: #F0F0F0;

  &:hover {
    color: #F0F0F0;
  }
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 24px;
    left: 0;
    background-color: #dee7e7;
    transform: scaleX(0);
    transition: transform 0.25s ease-in-out;
    transform-origin: right;
  }
  
  &:focus:after,
  &:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }
`;

const Hamburger = styled(Burger)`
  display: none;
  border: 0;
  margin-right: 20px;
  cursor: pointer;
  font-size: 10rem;
  
  @media screen and (max-width: 900px) {
    display: block;
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${props => props.open ? 1 : 0};
  height: 100vh;
  width: 100vw;
  background-color: #363636;
  transition: all 0.2s ease;
  z-index: 1;
  display: flex;
  align-items: center;
  visibility:  hidden;
  
  @media screen and (max-width: 900px) {
    visibility:  ${props => props.open ? 'visible' : 'hidden'};
  }
`;

const MenuInnerNav = styled.nav`
  margin: 0 auto;
  width: 75%;
`;

const MenuInnerList = styled.ul`
  font-family: 'Montserrat', sans-serif;
  font-size: 28rem;
  list-style: none;
  border-left: solid #dedede 1px;
`;

const MenuInnerListItem = styled.li`
  & + & {
    margin-top: 10px;
  }
`;

const MenuInnerListNavigationItem = styled(Link)`
  transition: all .2s ease-in-out;
  text-decoration: none;
  color: #dedede;
  
  &:hover{
    color: #F0F0F0;
  }
`;

const HeaderNav = styled.header`
  position: fixed;
  z-index: 20;
  display: ${props => props.loaded ? 'flex' : 'none'};
  background-color: ${props => props.menuOpen ? 'transparent' : props.backgroundColor};
  height: 70px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: background-color .2s ease;
`;

const LogoSvg = styled(Logo)`
  width: 40px;
  height: 40px;
  margin-left: 10px;
`;

function Header() {
  let header = null;
  let logoText = null;

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  useEffect(() => {
    if (!state.hasLoaded) {
      dispatch({ type: 'PAGE_LOAD' });
      TweenLite.from(header, 0.8, { y: -150 });
    }
  });

  return (
    <React.Fragment>
      <HeaderNav
        ref={h => header = h}
        backgroundColor={state.navColor}
        loaded={state.hasLoaded}
        menuOpen={state.menuOpen}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <LogoWrapper>
            <React.Fragment>
              <LogoSvg/>
              <CeramarkLogoLabel ref={h => logoText = h}>
                Ceramark
              </CeramarkLogoLabel>
            </React.Fragment>
          </LogoWrapper>
        </Link>

        <Nav>
          <NavbarUl>
            <NavbarLi>
              <NavigationItem to="/">
                About
              </NavigationItem>
            </NavbarLi>
            <NavbarLi>
              <NavigationItem to="/electrodes">
                Electrodes
              </NavigationItem>
            </NavbarLi>
            <NavbarLi>
              <NavigationItem to="/refractories">
                Refractories
              </NavigationItem>
            </NavbarLi>
            <NavbarLi>
              <NavigationItem to="/graphite-materials">
                Graphite Materials
              </NavigationItem>
            </NavbarLi>
            <NavbarLi>
              <NavigationItem to="/gouging-carbon">
                Gouging Carbon
              </NavigationItem>
            </NavbarLi>
            <NavbarLi>
              <NavigationItem to="/contact">
                Contact
              </NavigationItem>
            </NavbarLi>
          </NavbarUl>
          <Hamburger
            isOpen={state.menuOpen}
            onClick={
              () => {
                dispatch({ type: 'MENU_TOGGLE' });
              }
            }
          >
            <span/>
            <span/>
            <span/>
          </Hamburger>
        </Nav>
      </HeaderNav>
      <Menu open={state.menuOpen} backgroundColor={state.navColor}>
        <MenuInnerNav>
          <MenuInnerList>
            <MenuInnerListItem>
              <MenuInnerListNavigationItem onClick={() => {
                dispatch({ type: 'MENU_CLOSE' });
              }} to="/">
                Home
              </MenuInnerListNavigationItem>
            </MenuInnerListItem>
            <MenuInnerListItem>
              <MenuInnerListNavigationItem onClick={() => {
                dispatch({ type: 'MENU_CLOSE' });
              }} to="/electrodes">
                Electrodes
              </MenuInnerListNavigationItem>
            </MenuInnerListItem>
            <MenuInnerListItem>
              <MenuInnerListNavigationItem onClick={() => {
                dispatch({ type: 'MENU_CLOSE' });
              }} to="/refractories">
                Refractories
              </MenuInnerListNavigationItem>
            </MenuInnerListItem>
            <MenuInnerListItem>
              <MenuInnerListNavigationItem onClick={() => {
                dispatch({ type: 'MENU_CLOSE' });
              }} to="/graphite-materials">
                Graphite Materials
              </MenuInnerListNavigationItem>
            </MenuInnerListItem>
            <MenuInnerListItem>
              <MenuInnerListNavigationItem onClick={() => {
                dispatch({ type: 'MENU_CLOSE' });
              }} to="/gouging-carbon">
                Gouging Carbon
              </MenuInnerListNavigationItem>
            </MenuInnerListItem>
            <MenuInnerListItem>
              <MenuInnerListNavigationItem onClick={() => {
                dispatch({ type: 'MENU_CLOSE' });
              }} to="/contact">
                Contact
              </MenuInnerListNavigationItem>
            </MenuInnerListItem>
          </MenuInnerList>
        </MenuInnerNav>
      </Menu>
    </React.Fragment>
  );
}

export default Header;
