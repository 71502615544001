import React from 'react';
import styled from 'styled-components';
import Logo from '../svg/logo.inline.svg';
import { Link } from 'gatsby';

const StyledFooter = styled.footer`
  background-color: #181818;
  position: relative;
  width: 100%;
`;

const ContainerTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px;
  max-width: 1020px;
  margin: auto;
  border-bottom: solid #2e2e2e 1px;
  
  @media screen and (max-width: 1220px) {
      width: 90%;
  }
  
  @media screen and (max-width: 900px) {
    width: 95%;
  }
  
  @media screen and (max-width: 675px) {
    flex-wrap: wrap;
  }
`;

const ContainerBottom = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0 0;
  max-width: 1020px;
  margin: auto;
  
  @media screen and (max-width: 1220px) {
    width: 90%;
  }
  
  @media screen and (max-width: 900px) {
    width: 95%;
  }
`;

const CeramarkLogo = styled(Logo)`
  width: 40px;
  height: 40px;
  
  @media screen and (max-width: 900px) {
    width: 25px;
    height: 25px;
  }
  
  @media screen and (max-width: 675px) {
    width: 20px;
    height: 20px;
  }
`;

const FooterLink = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 12rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-decoration: none;
  color: #dee7e7;
  
  @media screen and (max-width: 900px) {
    font-size: 10rem;
  }
`;

const CeramarkName = styled.h1`
  color: #dee7e7;
  font-family: 'Montserrat', sans-serif;
  font-size: 26rem;
  font-weight: 400;
  margin-left: 10px;
  
  @media screen and (max-width: 900px) {
    font-size: 18rem;
  }
  
  @media screen and (max-width: 675px) {
    font-size: 14rem;
  }
`;

const Copyright = styled.p`
  color: #6e7373;
  font-family: 'Montserrat', sans-serif;
  font-size: 12rem;
  font-weight: 400;
  justify-self: flex-end;
  align-self: flex-end;
`;

const BottomLink = styled(Link)`
  color: #6e7373;
  font-family: 'Montserrat', sans-serif;
  font-size: 12rem;
  font-weight: 400;
  text-decoration: none;
`;

const TopNav = styled.ul`
  width: 75%;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  
  @media screen and (max-width: 675px) {
    width: 100%;
    padding: 10px 0 0;
    flex-direction: column;
  }
`;

const TopItem = styled.li`
  list-style: none;
  display: inline-block;
  margin: 0;
  
  @media screen and (max-width: 675px) {
    & + & {
      margin-top: 10px;
    }
  }
`;

const BottomNav = styled.ul`
  margin-left: auto;
`;

const BottomItem = styled.li`
  list-style: none;
  display: inline-block;
  margin: auto;

  & + & {
    margin-left: 15px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  @media screen and (max-width: 675px) {
    width: 100%;
    flex-basis: 100%;
  }
`;

const Footer = (props) => {
  return (
    <StyledFooter {...props}>
      <ContainerTop>
        <LogoWrapper>
          <CeramarkLogo/>
          <CeramarkName>
            Ceramark
          </CeramarkName>
        </LogoWrapper>
        <TopNav>
          <TopItem>
            <FooterLink to="/electrodes">
              Electrodes
            </FooterLink>
          </TopItem>
          <TopItem>
            <FooterLink to="/refractories">
              Refractories
            </FooterLink>
          </TopItem>
          <TopItem>
            <FooterLink to="/graphite-materials">
              Graphite Materials
            </FooterLink>
          </TopItem>
          <TopItem>
            <FooterLink to="/gouging-carbon">
              Gouging Carbon
            </FooterLink>
          </TopItem>
        </TopNav>
      </ContainerTop>
      <ContainerBottom>
        <Copyright>
          © 2020 Ceramark Technology Inc.
        </Copyright>
        <BottomNav>
          <BottomItem>
            <BottomLink to="/">
              About
            </BottomLink>
          </BottomItem>
          <BottomItem>
            <BottomLink to="/contact">
              Contact
            </BottomLink>
          </BottomItem>
        </BottomNav>
      </ContainerBottom>
    </StyledFooter>
  );
};

export default Footer;
